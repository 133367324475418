import React from 'react'
import Hero from '../components/Elements/Home/Hero'
import Sections from '../components/Elements/Home/Sections'
import Grid from '../components/Elements/Home/Grid'
import Team from '../components/Elements/Home/Team'

const Home = () => {
  return (
    <>
      <Hero/>
      <Sections/>
      <Grid/>
      {/* <Team/> */}
    </>
  )
}

export default Home