import React from "react";
import Hero from "../components/Elements/Accessibility/Hero";
import Message from "../components/Elements/Accessibility/Message";
import Grid from "../components/Elements/Accessibility/Grid";
import Setting from "../components/Elements/Accessibility/Setting";

const Accessibility = () => {
  return (
    <>
      <Hero/>
      <Message/>
      <Grid/>
      <Setting/>
    </>
  );
};

export default Accessibility;
