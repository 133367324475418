/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { FaDiscord, FaTiktok, FaTwitter } from "react-icons/fa";
import { FaReddit, FaTelegram, FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <footer
        className='footer text-center text-lg-start text-white py-6'
        style={{ backgroundColor: "#D441F3" }}>
        <div className='flex justify-center space-x-3'>
          <Link
            to='/faqs'
            className='text-3xl font-sans text-[#620250] hover:text-[#620250] no-underline border-b-[1px] border-[#620250] uppercase'>
            faq
          </Link>
          {/* <Link
            to='/presskit'
            className='text-3xl font-sans text-[#620250] hover:text-[#620250] no-underline border-b-[1px] border-[#620250] capitalize'>
            press
          </Link> */}
          <Link
            to='/contact'
            className='text-3xl font-sans text-[#620250] hover:text-[#620250] no-underline border-b-[1px] border-[#620250] capitalize'>
            contact
          </Link>
        </div>
        <div className='flex justify-center space-x-3 py-6'>
          <a
            href='https://discord.gg/Ws5E6TNnDk'
            target='_blank'
            rel='noopener noreferrer'>
            <FaDiscord color='#fff' size={38} className='mx-3' />
          </a>
          <a
            href='https://twitter.com/Rabletsofficial'
            target='_blank'
            rel='noopener noreferrer'>
            <FaXTwitter color='#fff' size={38} className='mx-3' />
          </a>
          <a
            href='https://t.me/Rabletsofficial'
            target='_blank'
            rel='noopener noreferrer'>
            <FaTelegram color='#fff' size={38} className='mx-3' />
          </a>
          <a
            href='https://www.reddit.com/r/Rabletsofficial/'
            target='_blank'
            rel='noopener noreferrer'>
            <FaReddit color='#fff' size={38} className='mx-3' />
          </a>
        </div>
        <p className='text-center text-white font-sans m-0 text-xl'>
          copyright © 2024{" "}
        </p>
        <p className='text-center text-white font-sans m-0 text-xl'>
          © RabLets All Right Reserved
        </p>
      </footer>
    </>
  );
};

export default Footer;
