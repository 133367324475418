/** @format */

import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";

const Hero = () => {
  const [showVideo, setShowVideo] = useState(false);

  const playVideo = () => {
    setShowVideo(true);
  };
  return (
    <>
      <div className='bg-[#F8D6FF] px-4 py-3'>
        <div className='border-b-[1px] border-[#620250]'></div>
        <div className='relative flex justify-center pt-32'>
          {!showVideo && (
            <>
              <img
                src='https://i.imgur.com/HB3tvWE.png'
                className='lg:w-4/6 img-fluid rounded-lg mt-10'
                alt=''
              />
              <div className='absolute lg:h-[80%] h-[50%] flex items-center'>
                <FaPlay
                  className='cursor-pointer'
                  color='#fff'
                  size={30}
                  onClick={playVideo}
                />
              </div>
            </>
          )}
          <div className='absolute flex justify-center top-3 z-10'>
            <img
              src='assets/images/hero2.png'
              className='w-6/12 img-fluid'
              alt=''
            />
          </div>
          {showVideo && (
            <div className='relative '>
              <iframe
                className='lg:w-[900px] lg:h-[500px] rounded-lg'
                src='https://www.youtube.com/embed/wu6ydJj_v8M?feature=oembed&autoplay=1&enablejsapi=1'
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
                title='Rab Lets Story Trailer | Wholesome Snack: The Game Awards Edition'
                style={{ opacity: 1 }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Hero;
