/** @format */

import React from "react";

const Grid = () => {
  return (
    <>
      <div className='bg-[#620250] px-4 pt-20 bg-clip'>
        <h3 className='text-white font-sans font-xl font-normal max-w-4xl m-auto tracking-wide text-center pb-12 '>
          Your ultimate goal is to deliver mail to all the animals in the
          forest, ensuring that each recipient receives their correspondence in
          a timely manner. Along the way, uncover the mysteries of the forest
          and forge meaningful connections with its inhabitants.
        </h3>
        <div className='grid lg:grid-cols-2 gap-3 mt-3'>
          <div className='flex lg:justify-end justify-center items-center'>
            <img
              src='https://i.imgur.com/Ro2inZA.png'
              className='lg:w-2/3 rounded-lg'
              alt=''
            />
          </div>
          <div>
            {/* <img src='assets/images/b1.png' alt='' /> */}
            <h2 className=' font-sans  text-transparent bg-gradient-to-r from-[#F5B7A0] via-[#F1EC9C] to-[#DAC4F4] bg-clip-text  text-4xl font-bold'>
              ✨ Cozy Cottagecore adventure ✨
            </h2>
            <ul className='text-white list-disc lg:w-3/4'>
              <li className='lg:text-xl font-sans font-semibold mt-2'>
                🐸 You meet with 10+ overwhelming animal characters play as a
                brave adventurer who has taken on the responsibility of
                delivering mail to the animals living in the forest.
              </li>
              <li className='lg:text-xl font-sans font-semibold mt-3'>
                🌷 The forest is vast and filled with lush greenery, towering
                trees, winding paths, and hidden secrets waiting to be
                discovered.
              </li>
              <li className='lg:text-xl font-sans font-semibold mt-3'>
                💌 Your primary companions in this adventure are Autonomous AI
                Agents, equipped with advanced navigation capabilities to help
                you deliver mail efficiently.
              </li>
            </ul>
          </div>
        </div>

        <div className='grid lg:grid-cols-2 gap-3 mt-3'>
          <div className='flex lg:justify-start justify-center items-end flex-col'>
            <div>
              {/* <img src='assets/images/b2.png' alt='' /> */}
              <h2 className=' font-sans  text-transparent bg-gradient-to-r from-[#F5B7A0] via-[#F1EC9C] to-[#DAC4F4] bg-clip-text  text-4xl font-bold'>
              ✨ Mail Scout Badges ✨
            </h2>
            </div>
            <ol className='text-white list-decimal lg:w-3/4'>
              <p className='lg:text-xl font-sans font-semibold mt-2'>
                Earn prestigious Mail Scout badges by completing daring
                challenges and delivering mail to the forest inhabitants. Each
                badge you acquire enhances your gliding ability, allowing you to
                perform even more impressive aerial maneuvers and reach new
                heights in your adventure.
              </p>
              <li className='lg:text-xl font-sans font-semibold mt-2'>
                🌈 Over 10 million customization are possible!
              </li>
              <li className='lg:text-xl font-sans font-semibold mt-3'>
                🍄 Use more than 10 categories ranging from skin tone to design
                your mail scout.
              </li>
              <li className='lg:text-xl font-sans font-semibold mt-3'>
                ⭐ Upgrade your gliding ability once you got mail scout badge.
              </li>
            </ol>
          </div>
          <div className='flex justify-start items-center'>
            <img src='assets/images/g2.png' className='lg:w-2/3' alt='' />
          </div>
        </div>

        <div className='grid lg:grid-cols-2 gap-3 mt-3'>
          <div className='flex lg:justify-end justify-center items-center'>
            <img
              src='https://i.imgur.com/QzMKIl2.png'
              className='lg:w-2/3 rounded-lg'
              alt=''
            />
          </div>
          <div>
            {/* <img src='assets/images/b3.png' alt='' /> */}
            <h2 className=' font-sans  text-transparent bg-gradient-to-r from-[#F5B7A0] via-[#F1EC9C] to-[#DAC4F4] bg-clip-text  text-4xl font-bold'>
              ✨ Explore a Whimsical Flora ✨
            </h2>
            <ul className='text-white list-disc lg:w-3/4'>
              <p className='lg:text-xl font-sans font-semibold mt-2'>
                Diverse Landscapes: Explore eight distinct areas straight from
                the pages of a storybook. From the bustling woodland village
                with its quaint cottages to the mysterious depths of the murky
                swamp.
              </p>
              <li className='lg:text-xl font-sans font-semibold mt-2'>
                🍀 Collectible Trinkets: Embark on a quest to collect hidden
                trinkets scattered throughout the forest.
              </li>
              <li className='lg:text-xl font-sans font-semibold mt-3'>
                🌟 Aerial Acrobatics: Take your exploration to new heights with
                gliding and jumping abilities.
              </li>
              <li className='lg:text-xl font-sans font-semibold mt-3'>
                🎈 No Pressure Exploration: Enjoy the freedom to explore the
                forest at your own pace and leisure. There are no time limits,
                no pressure, and certainly no risk of harm.
              </li>
              <li className='lg:text-xl font-sans font-semibold mt-3'>
                Accessibility Options: Various accessibility options are
                available to ensure that everyone can enjoy the journey through
                the enchanted realms.
              </li>
              <li className='lg:text-xl font-sans font-semibold mt-3'>
                Flexible Progression: Proceed through the story at your own
                pace, taking as long or as short as you desire. Whether you
                prefer to immerse yourself in every detail or blaze through the
                adventure, the choice is yours.
              </li>
            </ul>
          </div>
          <p className='lg:text-xl font-sans font-semibold mt-2 text-white'>
            Safe Exploration: Fear not the perils of the forest – there's no
            fall damage here! If you stumble or take a misstep, simply pick
            yourself back up and try again.
          </p>
        </div>

        <div className='grid lg:grid-cols-2 gap-3 mt-3'>
          <div className='flex lg:justify-start justify-center items-end flex-col '>
            <div>
              {/* <img src='assets/images/b4.png' alt='' /> */}
              <h2 className=' font-sans text-start text-transparent bg-gradient-to-r from-[#F5B7A0] via-[#F1EC9C] to-[#DAC4F4] bg-clip-text  text-4xl font-bold'>
              ✨ Rablets Gameplay
✨
            </h2>
            </div>
            <ul className='text-white list-disc lg:w-3/4'>
              <li className='lg:text-xl font-sans font-semibold mt-3'>
                🐌 Leisurely Exploration: Take a leisurely stroll through the
                forest, immersing yourself in its beauty and charm.
              </li>
              <li className='lg:text-xl font-sans font-semibold mt-2'>
                🌻 Accessibility Options: We believe that everyone should have
                the opportunity to experience the wonders of the forest.
              </li>
              <li className='lg:text-xl font-sans font-semibold mt-3'>
                🍃 Personalized Progression: Proceed through the story at your
                own pace, taking as much time as you need to savor each moment
                and discover every hidden gem the forest has to offer.
              </li>
            </ul>
            <p className='lg:text-xl font-sans font-semibold mt-2 text-white'>
              Safe Exploration: Fear not the perils of the forest – there's no
              risk of harm here. With no fall damage, you're free to explore
              every nook and cranny without worry.
            </p>
          </div>

          <div className='flex justify-start items-center'>
            <img
              src='https://i.imgur.com/3OW87HI.png'
              className='lg:w-2/3 rounded-lg '
              alt=''
            />
          </div>
        </div>
        <div className='py-14'>
          <h4 className='text-center lg:text-5xl font-sans fw-semibold text-[#DC5E00]'>
            JOIN THE COMMUNITY:
          </h4>
          <div className='flex justify-center'>
            <a href="https://discord.gg/Ws5E6TNnDk" target="_blank" rel="noopener noreferrer" className='relative no-underline cursor-pointer opacity-90 text-2xl font-semibold capitalize hover:opacity-100 transition-opacity p-[2px] font-sans bg-black rounded-[16px] bg-gradient-to-t from-[#8122b0] to-[#dc98fd] active:scale-95'>
              <span className='w-full h-full flex items-center gap-2 px-8 py-3 bg-[#B931FC] text-[#f1d5fe] rounded-[14px] bg-gradient-to-t from-[#a62ce2] to-[#c045fc]'>
                Join on discord
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Grid;
