/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { FaDiscord, FaTiktok, FaTwitter } from "react-icons/fa";
import { FaReddit, FaTelegram, FaXTwitter } from "react-icons/fa6";

const Sections = () => {
  return (
    <>
      <div className='bg-[#F8D6FF] lg:px-4 lg:pt-20'>
        {/* <div className='flex justify-center items-center flex-wrap'>
          <Link
            to='#'
            className='no-underline lg:text-3xl font-sans font-semibold border-b-[1px] border-[#620250] text-[#620250] hover:text-[#620250] '>
            Nintendo Switch
          </Link>
          <span className='mx-2 text-[#DC5E00] lg:text-2xl'>✦</span>
          <Link
            to='#'
            className='no-underline lg:text-3xl font-sans font-semibold border-b-[2px] border-[#620250] text-[#620250] hover:text-[#620250] '>
            Steam
          </Link>
          <span className='mx-2 text-[#DC5E00] lg:text-2xl'>✦</span>
          <h5 className='no-underline lg:text-3xl font-sans font-semibold text-[#620250] hover:text-[#620250] '>
            Playstation 4 + 5
          </h5>
        </div> */}
        <div className='flex justify-center  max-w-xl m-auto'>
          <h5 className='text-center lg:text-4xl font-sans fw-semibold text-[#DC5E00]'>
            Forest Mail Quest Adventure Game Integrating Autonomous AI Agents to
            delivering mail to animals across forest.
          </h5>
        </div>
        <div className='flex justify-center mt-14 '>
          <h5 className='text-center lg:text-6xl font-sans fw-semibold text-[#620250]'>
            NINTENDO SWITCH <br /> OUT NOW!
          </h5>
        </div>
        <div className='flex justify-center'>
          <button className='relative cursor-pointer opacity-90 text-2xl font-semibold capitalize hover:opacity-100 transition-opacity p-[2px] font-sans bg-black rounded-[16px] bg-gradient-to-t from-[#8122b0] to-[#dc98fd] active:scale-95'>
            <span className='w-full h-full flex items-center gap-2 px-8 py-3 bg-[#B931FC] text-[#f1d5fe] rounded-[14px] bg-gradient-to-t from-[#a62ce2] to-[#c045fc]'>
              Buy on the e-shop
            </span>
          </button>
        </div>
        <div>
          <h5 className='text-center lg:text-4xl font-sans fw-semibold py-4 text-[#620250]'>
            PS4/5 LATER TODAY :)
          </h5>
        </div>
        <div>
          <h5 className='text-center lg:text-6xl font-sans fw-semibold py-4 text-[#620250]'>
            PC/MAC:
          </h5>
        </div>
        <div className='flex justify-center'>
          <button className='relative cursor-pointer opacity-90 text-2xl font-semibold capitalize hover:opacity-100 transition-opacity p-[2px] font-sans bg-black rounded-[16px] bg-gradient-to-t from-[#8122b0] to-[#dc98fd] active:scale-95'>
            <span className='w-full h-full flex items-center gap-2 px-8 py-3 bg-[#B931FC] text-[#f1d5fe] rounded-[14px] bg-gradient-to-t from-[#a62ce2] to-[#c045fc]'>
              Buy on Steam
            </span>
          </button>
        </div>
        <div className='flex justify-center py-14'>
          <a
            href='https://discord.gg/Ws5E6TNnDk'
            target='_blank'
            rel='noopener noreferrer'>
            <FaDiscord
              color='#fff'
              size={40}
              className='bg-[#620250] p-2 rounded-full mx-3'
            />
          </a>
          <a
            href='https://twitter.com/Rabletsofficial'
            target='_blank'
            rel='noopener noreferrer'>
            <FaXTwitter
              color='#fff'
              size={40}
              className='bg-[#620250] p-2 rounded-full mx-3'
            />
          </a>
          <a
            href='https://t.me/Rabletsofficial'
            target='_blank'
            rel='noopener noreferrer'>
            <FaTelegram
              color='#fff'
              size={40}
              className='bg-[#620250] p-2 rounded-full mx-3'
            />
          </a>
          <a
            href='https://www.reddit.com/r/Rabletsofficial/'
            target='_blank'
            rel='noopener noreferrer'>
            <FaReddit
              color='#fff'
              size={40}
              className='bg-[#620250] p-2 rounded-full mx-3'
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default Sections;
