/** @format */

import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "fullscreen",
    answer: (
      <div>
        <p>
          Using this toggle you can switch the game between windowed (unchecked)
          and windowed fullscreen (checked).
        </p>
        <ul>
          <p>
            <span className='font-semibold'>Windowed</span> (unchecked){" "}
          </p>
          <li className='pl-4'>
            Includes the toolbar at the top should you want to minimize the game
            or close it using the X (note that this will lose any unsaved
            progress).
          </li>
        </ul>
        <ul>
          <p>
            <span className='font-semibold'>Windowed</span> fullscreen (checked){" "}
          </p>
          <li className='pl-4'>
            Gives you the benefit of a fullscreen game (no toolbar) while still
            allowing you to tab out of the game without it disappearing from
            your screen.
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: "Resolution",
    answer: (
      <div>
        <p>
          with the resolution dropdown menu you can select the resolution the
          game runs at.
        </p>
        <p>
          The game automatically selects the resolution of your monitor but you
          can choose to run it at a different resolution of course. Choosing a
          lower resolution can improve performance.
        </p>
      </div>
    ),
  },
  {
    question: "vsync",
    answer: (
      <div>
        <p>
          Vertical Synchronization (VSync), helps create stability by
          synchronizing the image frame rate of the game with your display
          monitor refresh rate.
        </p>
        <p>If this is turned off it could cause the game to look glitched.</p>
      </div>
    ),
  },
  {
    question: "fps limit",
    answer: (
      <div>
        <p>
          You can limit the game’s framerate (FPS) if you want! The benefit to
          limiting framerate would be to force the game to use less of your PC’s
          resources.
        </p>
      </div>
    ),
  },
  {
    question: "View Distance (big performance saver)",
    answer: (
      <div>
        <p>This limits how far the game displays 3d models in the game.</p>
        <p>Limiting this can help performance a lot.</p>
      </div>
    ),
  },
  {
    question: "post processing",
    answer: (
      <div>
        <p>
          this reduces the post processing effects on the game (slight color
          correction, brightness and contrast settings) and turning it down
          could help performance slightly.
        </p>
      </div>
    ),
  },
  {
    question: "shadows",
    answer: (
      <div>
        <p>‘Shadows’ determines how nice the game’s shadows are. </p>
        <p>
          Limiting these can help performance but will affect the look of Mail
          Time quite a bit. The game looks best with this setting on High or
          Epic.
        </p>
        <p>
          This setting doesn’t take full effect until you save and restart the
          game.
        </p>
      </div>
    ),
  },
  {
    question: "effects",
    answer: (
      <div>
        <p>
          ‘Effects’ determines how nice the game’s particles are, limiting these
          can really help performance if you notice a lot of lag near waterfalls
          or collectible items.
        </p>
      </div>
    ),
  },
  {
    question: "textures",
    answer: (
      <div>
        <p>
          ‘Textures’ determines how nice the game’s textures are, limiting these
          can help performance.
        </p>
      </div>
    ),
  },
  {
    question: "shading",
    answer: (
      <div>
        <p>
          ‘Shading’ determines how nice the game’s shaders are, mostly affecting
          the water. Limiting this setting can help performance if you notice
          issues when near or looking at water.
        </p>
      </div>
    ),
  },
];
const Faq3 = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to toggle the active FAQ item
  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className='bg-[#F8D6FF] px-4 py-12'>
        <div className='container'>
          <div className='row gap-14'>
            <div className='col-md-4'>
              <h2 className='lg:text-4xl font-sans font-bold py-2 text-[#620250]'>
                GRAPHICAL SETTINGS
              </h2>
              <p className='lg:text-xl font-sans font-normal  text-[#620250]'>
                On the right side of the settings page there’s a sub menu
                selector, this concerns the second section ‘Graphics’.
              </p>
              <img
                src='https://i.imgur.com/Fm9RBq9.png'
                className='img-fluid rounded-3xl'
                alt=''
              />
            </div>
            <div className='col-md-7'>
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className='mb-4 pt-4 border-t-[1px] border-[#620250]'>
                  <div
                    className='flex justify-between items-center cursor-pointer'
                    onClick={() => toggleFAQ(index)}>
                    <h2 className='lg:text-center lg:text-2xl text-lg font-sans uppercase font-semibold text-[#620250] '>
                      {faq.question}
                    </h2>
                    <span className='text-blue-500'>
                      {activeIndex === index ? (
                        <MdKeyboardArrowUp color='#620250' size={30} />
                      ) : (
                        <MdKeyboardArrowDown color='#620250' size={30} />
                      )}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <p className='mt-2 lg:text-xl font-sans font-normal text-[#620250]'>
                      {faq.answer}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq3;
