/** @format */

import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "autosave & autosave interval",
    answer: (
      <div>
        <p>
          With ‘Autosave’ you can toggle the game’s automatic saving function on
          and off.
        </p>
        <p>
          By default this feature is on and will save the game at various
          moments throughout the game; finishing a conversation, picking up
          items, closing the handbook. The benefit of this feature is that the
          game saves frequently and thus you’re unlikely to lose progress.
        </p>
        <p>
          The Autosave Interval slider allows you to adjust the interval at
          which the game will save automatically without any input. By default
          it’s every 15 minutes (this can be adjusted from 1 to 30 minutes using
          the slider).
        </p>
      </div>
    ),
  },
  {
    question: "Contrast & Brightness",
    answer: (
      <div>
        <p>
          Using these sliders the game’s contrast and brightness can be adjusted
          respectively. This doesn’t affect UI (any menus) but can be helpful if
          you have difficulty seeing things in the game environment.
        </p>
      </div>
    ),
  },
  {
    question: "Idle Sit Timer",
    answer: (
      <div>
        <p>
          When you stand still for 10 seconds the player character will
          automatically sit down. With the Idle Sit time slider you can set it
          anywhere from 0 to 20 seconds. Setting this slider to 0 will disable
          to feature entirely.
        </p>
      </div>
    ),
  },
];

const Faq2 = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to toggle the active FAQ item
  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className='bg-[#eebcf8] px-4 py-12'>
        <div className='container'>
          <div className='row gap-14'>
            <div className='col-md-4'>
              <h2 className='lg:text-4xl font-sans font-bold py-2 text-[#620250]'>
                GENERAL SETTINGS
              </h2>
              <p className='lg:text-xl font-sans font-normal  text-[#620250]'>
                On the right side of the settings page there’s a sub menu
                selector, this concerns the top section ‘General’.
              </p>
              <img
                src='https://i.imgur.com/QzMKIl2.png'
                className='img-fluid rounded-3xl'
                alt=''
              />
            </div>
            <div className='col-md-7'>
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className='mb-4 pt-4 border-t-[1px] border-[#620250]'>
                  <div
                    className='flex justify-between items-center cursor-pointer'
                    onClick={() => toggleFAQ(index)}>
                    <h2 className='lg:text-center lg:text-2xl text-lg font-sans uppercase font-semibold text-[#620250] '>
                      {faq.question}
                    </h2>
                    <span className='text-blue-500'>
                      {activeIndex === index ? (
                        <MdKeyboardArrowUp color='#620250' size={30} />
                      ) : (
                        <MdKeyboardArrowDown color='#620250' size={30} />
                      )}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <p className='mt-2 lg:text-xl font-sans font-normal text-[#620250]'>
                      {faq.answer}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq2;
