/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaDiscord, FaTiktok } from "react-icons/fa";
import { FaTelegram, FaXTwitter } from "react-icons/fa6";


const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <nav className='bg-[#F8D6FF] px-4 py-3'>
        <div className='container-fluid mx-auto flex justify-between items-center border-[#620250]'>
          <div className='w-44'>
            <Link to='/' className='text-white font-bold text-xl'>
              <img
                src='assets/images/logo.png'
                style={{ width: "150px" }}
                alt=''
              />
            </Link>
          </div>
          <div className='hidden md:flex flex-wrap justify-center space-x-6 '>
            <Link
              to='/'
              className='text-[#620250] hover:text-[#620250] font-sans  text-3xl no-underline capitalize'>
              Home
            </Link>
            <Link
              to='/faqs'
              className='text-[#620250] hover:text-[#620250] font-sans  text-3xl no-underline capitalize'>
              FAQ
            </Link>
            <Link
              to='/contact'
              className='text-[#620250] hover:text-[#620250] font-sans  text-3xl no-underline capitalize'>
              Contact
            </Link>
            {/* <Link
              to='/presskit'
              className='text-[#620250] hover:text-[#620250] font-sans  text-3xl no-underline capitalize'>
              Presskit
            </Link> */}
            <Link
              to='/accessibility'
              className='text-[#620250] hover:text-[#620250] font-sans  text-3xl no-underline capitalize'>
              Accessibility
            </Link>
          </div>
          <div className='lg:flex justify-between items-center hidden'>
            <a href="https://discord.gg/Ws5E6TNnDk" target="_blank" rel="noopener noreferrer"><FaDiscord color='#620250' size={18} className='mx-3' /></a> 
            <a href="https://twitter.com/Rabletsofficial" target="_blank" rel="noopener noreferrer"><FaXTwitter color='#620250' size={18} className='mx-3' /></a> 
           <a href="https://t.me/Rabletsofficial" target="_blank" rel="noopener noreferrer"><FaTelegram color='#620250' size={18} className='mx-3' /></a> 
            <button className='relative cursor-pointer opacity-90 hover:opacity-100 transition-opacity p-[2px] font-sans bg-black rounded-[16px] bg-gradient-to-t from-[#8122b0] to-[#dc98fd] active:scale-95'>
              <span className='w-full h-full flex items-center gap-2 px-8 py-3 bg-[#B931FC] text-[#f1d5fe] rounded-[14px] bg-gradient-to-t from-[#a62ce2] to-[#c045fc]'>
                <svg
                  strokeWidth={2}
                  strokeLinejoin='round'
                  strokeLinecap='round'
                  stroke='currentColor'
                  fill='none'
                  className='w-5 h-5'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path d='M8 13V9m-2 2h4m5-2v.001M18 12v.001m4-.334v5.243a3.09 3.09 0 0 1-5.854 1.382L16 18a3.618 3.618 0 0 0-3.236-2h-1.528c-1.37 0-2.623.774-3.236 2l-.146.292A3.09 3.09 0 0 1 2 16.91v-5.243A6.667 6.667 0 0 1 8.667 5h6.666A6.667 6.667 0 0 1 22 11.667Z' />
                </svg>
                Play Game
              </span>
            </button>
          </div>
          <div className='md:hidden'>
            {/* Mobile menu button (Hamburger icon) */}
            <button className='text-[#620250]' onClick={toggleMenu}>
              <svg
                className='h-6 w-6'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path d='M4 6h16M4 12h16m-7 6h7'></path>
              </svg>
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className='md:hidden'>
            <div className='flex flex-col text-center space-y-2'>
              <Link
                to='/'
                className='text-[#620250] hover:text-[#620250] font-medium no-underline capitalize'>
                Home
              </Link>
              <Link
                to='/faqs'
                className='text-[#620250] hover:text-[#620250] font-medium no-underline capitalize'>
                FAQ
              </Link>
              <Link
                to='/contact'
                className='text-[#620250] hover:text-[#620250] font-medium no-underline capitalize'>
                Contact
              </Link>
              {/* <Link
                to='/presskit'
                className='text-[#620250] hover:text-[#620250] font-medium no-underline capitalize'>
                Presskit
              </Link> */}
              <Link
                to='/accessibility'
                className='text-[#620250] hover:text-[#620250] font-medium no-underline capitalize'>
                Accessibility
              </Link>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Header;
