/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <div className='bg-[#DB59F7] py-5'>
        <h5 className='m-0 text-center lg:text-5xl font-sans font-semibold uppercase text-[#620250] '>
          Accessibility
        </h5>
      </div>
      <div className='bg-[#F8D6FF] px-4 pt-14 pb-8'>
        <div className='bg-[#F8D6FF]  lg:flex justify-center'>
          <div className='lg:w-3/5'>
            <p className='lg:text-3xl font-sans font-normal text-[#620250]  '>
              Thank you for your interest in Rab Lets' accessibility
              initiatives. Rab Lets is primarily developed by one individual
              (hello!), which imposes some limitations on what can be
              accomplished. Nonetheless, I've made efforts to incorporate as
              many accessibility features as possible into Rab Lets and plan to
              implement even more post-launch.
            </p>
            <h3 className=' lg:text-3xl font-sans font-semibold py-4 text-[#620250]'>
              IF YOU HAVE SUGGESTIONS OR ARE EXPERIENCING ISSUES PLEASE LET ME
              KNOW VIA EMAIL (
              <Link
                to='#'
                className='no-underline lg:text-3xl text-xl font-sans font-semibold py-4 text-[#DC5E00] hover:text-[#DC5E00] '>
                admin@rablets.com
              </Link>
              ) OR VIA
               <Link
                to='#'
                className='no-underline lg:text-3xl text-xl font-sans font-semibold py-4 text-[#DC5E00] hover:text-[#DC5E00] '>
                 DISCORD
              </Link>
              .
            </h3>

            <p className='lg:text-3xl font-sans tracking-tight font-normal lg:py-4 text-[#620250]'>
              I will do my very best to make the game playable for you!
            </p>
            <p className='lg:text-3xl font-sans tracking-tight font-normal lg:py-4 text-[#620250]'>
              I've been following the
              <Link
                to='#'
                className='no-underline lg:text-3xl font-sans font-normal py-4 mx-2 text-[#DC5E00] hover:text-[#DC5E00] '>
                game accessibility guidelines
              </Link>
              as my main frame of reference for accessibility in Rab Lets. You
              can view how their checklist is implemented in the game here:
            </p>
            <div className='flex justify-center'>
              <button className='relative cursor-pointer opacity-90 text-2xl font-semibold capitalize hover:opacity-100 transition-opacity p-[2px] font-sans bg-black rounded-[16px] bg-gradient-to-t from-[#8122b0] to-[#dc98fd] active:scale-95'>
                <span className='w-full h-full flex items-center gap-2 px-8 py-3 bg-[#B931FC] text-[#f1d5fe] rounded-[14px] bg-gradient-to-t from-[#a62ce2] to-[#c045fc]'>
                  Rab Lets accessibility <br /> checklist
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
