/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Faq1 from "./Faq1";
import Faq2 from "./Faq2";
import Faq3 from "./Faq3";
import Faq4 from "./Faq4";
import Faq5 from "./Faq5";

const Setting = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to toggle the active FAQ item
  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className='bg-gradient-to-t from-[#FCECFF] via-[#D854F3] to-[#F0ADFF] px-4 py-10'>
        <div className='flex justify-center items-center'>
          <h5 className='m-0 text-center lg:text-6xl px-6 py-4 font-sans bg-[#B931FC] text-[#f1d5fe] rounded-[14px] bg-gradient-to-t from-[#a62ce2] to-[#c045fc]font-sans font-bold uppercase '>
            IN-GAME SETTINGS
          </h5>
        </div>
      </div>

      <Faq1 />
      <Faq2 />
      <Faq3 />
      <Faq4 />
      <Faq5 />
    </>
  );
};

export default Setting;
