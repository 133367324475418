/** @format */

import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "What is Rab Lets?",
    answer: (
      <div className='container'>
        <p className='lg:text-3xl'>
          Rab Lets is a cottagecore adventure game about delivering mail to
          forest animals!
        </p>
        <div>
          <h3 className='font-semibold'>Features:</h3>
          <ul className='w-5/6'>
            <li className='list-disc mt-2'>
              <span className='font-semibold'>
                Explore the Grumblewood Grove any way you like -
              </span>
              Choose your own path to follow and see where it leads you!
            </li>
            <li className='list-disc mt-2'>
              <span className='font-semibold'>
                Deliver letters for the animals you meet along the way
              </span>
              - They might be able to help you as well!
            </li>
            <li className='list-disc mt-2'>
              <span className='font-semibold'>
                Glide around with the letters -
              </span>
              You might be able to find hidden treasure!
            </li>
            <li className='list-disc mt-2'>
              <span className='font-semibold'>Customize your character -</span>
              Play as your very own Mail Scout!
            </li>
            <li className='list-disc mt-2'>
              <span className='font-semibold'>Play at your own pace -</span>
              Stress-free accessible gameplay with no time pressure.
            </li>
          </ul>
          <div className='mt-4'>
            <h3 className='font-semibold'>Story:</h3>
            <p>
              Take on the role of a fresh-on-the-job Mail Scout on track to
              complete your very first mail delivery! You’ve been tasked with a
              letter to ‘Greg’ but the only thing you know is that he lives
              somewhere in Grumblewood Grove. Glide, jump and talk your way
              through the Grove on your way to deliver the letter.
            </p>
            <p className='mt-4'>
              All you have to figure out where exactly this Greg lives and hand
              him the letter, no biggie!
            </p>
          </div>
        </div>
      </div>
    ),
  },

  // {
  //   question: "who is working on Rab Lets?",
  //   answer: (
  //     <div className='container'>
  //       <p>
  //         Hello! My name is
  //         <Link
  //           to='#'
  //           className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //           Kela van der Deijl
  //         </Link>
  //         (she/her)! I'm the main person behind Rab Lets! Well, officially my
  //         company appelmoes games is, but that's just me so potato potatoh.
  //       </p>
  //       <p>
  //         I do coding, game design and a bunch of the art for the game (+ all
  //         the boring paperwork stuff).
  //       </p>
  //       <p>
  //         I have some lovely people helping me out with things I'm not good
  //         (read: bad) at:
  //       </p>
  //       <ul>
  //         <li className='list-disc mt-3'>
  //           <span className='font-semibold'>Bloo van der Deijl</span> (he/him) -
  //           writer <br />
  //           Takes care of all the dialogue you see in the game together with
  //           Maya.
  //         </li>
  //         <li className='list-disc mt-3'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Maya Bloem
  //           </Link>
  //           (she/her) - writer <br />
  //           Takes care of all the dialogue you see in the game together with
  //           Bloo​.
  //         </li>
  //         <li className='list-disc mt-3'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Mischa Penders
  //           </Link>
  //           (he/him) - 3D artist
  //           <br />
  //           Creates lovely 3D models alongside me to make the game look 100x
  //           better.
  //         </li>
  //         <li className='list-disc mt-3'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Gina Loughlin
  //           </Link>
  //           (she/her) - music composer
  //           <br />
  //           Creates all the amazing tunes you hear!​
  //         </li>
  //         <li className='list-disc mt-3'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Amerlyn Zeta
  //           </Link>
  //           (she/her) - sound designer
  //           <br />
  //           Creates all the funky sounds you hear!
  //         </li>

  //         <p className='py-4'>
  //           Along the way I've also had some help from various artists on some
  //           3d models for the <br /> game (ty!! {"<3"}):
  //         </p>
  //         <li className='list-disc mt-3'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Alex
  //           </Link>
  //           (he/him)
  //           <br />
  //         </li>
  //         <li className='list-disc mt-3'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Bente Flohr
  //           </Link>
  //           (any pronouns)
  //           <br />
  //         </li>
  //         <li className='list-disc mt-3'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Kira Ridzi
  //           </Link>
  //           (she/her)
  //           <br />
  //         </li>
  //         <li className='list-disc mt-3'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Samantha Heymans
  //           </Link>
  //           (she/her)
  //           <br />
  //         </li>
  //       </ul>
  //     </div>
  //   ),
  // },
  // {
  //   question: "what platforms?",
  //   answer: (
  //     <div className='container'>
  //       <p>Rab Lets is currently available on:</p>
  //       <ul>
  //         <li className='list-disc'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Steam
  //           </Link>
  //           & the
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Epic Games Store
  //           </Link>
  //           for PC/Mac <br /> and will release October 19th 2023 on:
  //         </li>
  //         <li className='list-disc mt-3'>Nintendo Switch</li>
  //         <li className='list-disc mt-3'>Playstation 4/5</li>
  //       </ul>
  //     </div>
  //   ),
  // },
  // {
  //   question: "where can I buy the game?",
  //   answer: (
  //     <dir className='container'>
  //       <p>you’re able to purchase Rab Lets in the following places:</p>
  //       <ul>
  //         <li className='list-disc'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Steam
  //           </Link>
  //           & the
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Epic Games Store
  //           </Link>
  //           for PC/Mac <br /> and will release October 19th 2023 on:
  //         </li>
  //         <p>October 19th you’ll also be able to get it on:</p>
  //         <li className='list-disc'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Nintendo eshop
  //           </Link>
  //           for the Nintendo Switch
  //         </li>
  //         <li className='list-disc'>
  //           <Link
  //             to='#'
  //             className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //             Playstation Store
  //           </Link>
  //           for PS4/5!
  //         </li>
  //       </ul>
  //     </dir>
  //   ),
  // },
  {
    question: "Switch/Playstation Release Date?",
    answer: (
      <div className='container'>
        <p>
          Rab Lets has released on{" "}
          <Link
            to='#'
            className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
            Nintendo Switch
          </Link>{" "}
          and will release on Playstation 4/5 later today!
        </p>
      </div>
    ),
  },
  {
    question: "phone/mobile/free to play?",
    answer: (
      <div className='container'>
        <p>
          No, Rab Lets is a paid game that you pay for upfront and will not
          release on mobile.
        </p>
      </div>
    ),
  },
  // {
  //   question: "price?",
  //   answer: (
  //     <div className='container'>
  //       <p>- - -</p>
  //     </div>
  //   ),
  // },
  {
    question: "Multiplayer/co-op?",
    answer: (
      <div className='container'>
        <p>
          Nope! That's a bit too much for me to handle right now so Rab Lets is
          a lovely singleplayer experience for you to enjoy :)
        </p>
      </div>
    ),
  },
  // {
  //   question: "Presskit?",
  //   answer: (
  //     <div className='container'>
  //       <p>
  //         You can find it{" "}
  //         <Link
  //           to='#'
  //           className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
  //           here
  //         </Link>
  //         !
  //       </p>
  //     </div>
  //   ),
  // },
  {
    question: "Can I make music for Rab Lets?",
    answer: (
      <div className='container'>
        <p>Rab Lets already has a composer, sorry! </p>
        <p className='mt-3'>
          Fan music is always appreciated and allowed but I’m not looking for
          another professional composer right now.
        </p>
      </div>
    ),
  },
  {
    question: "Can I work on Rab Lets?",
    answer: (
      <div className='container'>
        <p className='mt-3'>
          sorry no! The game’s already finished and the team has been complete
          for a while now. For any updates to the game we’ll be working with the
          same team. If you’d like to work with me in the future reach out and
          get to know me via{" "}
          <a
            href='https://twitter.com/Rabletsofficial'
            target='_blank'
            rel='noopener noreferrer'
            className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
            Twitter
          </a>{" "}
          or email!
        </p>
      </div>
    ),
  },
  {
    question: "Can I make fanart of Rab Lets?",
    answer: (
      <div className='container'>
        <p>YES!!! OF COURSE!! </p>
        <p>
          I’d love to see, please share it in our{" "}
          <a
            href='https://discord.gg/Ws5E6TNnDk'
            target='_blank'
            rel='noopener noreferrer'
            className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
            Discord
          </a>{" "}
          + tag me if you share it on socials please {"<3"}{" "}
        </p>
        <p>
          (@Rabletsofficial on{" "}
          <a
            href='https://twitter.com/Rabletsofficial'
            target='_blank'
            rel='noopener noreferrer'
            className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
            Twitter
          </a>{" "}
          or <br /> @Rabletsofficial on{" "}
          <a
            href='https://t.me/Rabletsofficial'
            target='_blank'
            rel='noopener noreferrer'
            className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
            Telegram
          </a>
          )
        </p>
      </div>
    ),
  },
  {
    question: "How do i contact you?",
    answer: (
      <div className='container'>
        <p className='mt-3'>
          Check out the{" "}
          <Link
            to='/contact'
            className='no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]'>
            {" "}
            contact
          </Link>{" "}
          page!
        </p>
      </div>
    ),
  },
];

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to toggle the active FAQ item
  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className='bg-[#F8D6FF] px-4 lg:pt-20'>
        <h5 className='m-0 text-center lg:text-5xl font-sans font-semibold text-[#620250] '>
          FREQUENTLY ASKED QUESTIONS & <br /> ANSWERS!
        </h5>
        <div className='container mt-16 pb-8'>
          {faqs.map((faq, index) => (
            <div key={index} className='mb-4 pt-4 border-t-4 border-[#eee]'>
              <div
                className='flex justify-between items-center cursor-pointer'
                onClick={() => toggleFAQ(index)}>
                <h2 className='lg:text-center lg:text-4xl text-lg font-sans uppercase font-semibold text-[#620250] '>
                  {faq.question}
                </h2>
                <span className='text-blue-500'>
                  {activeIndex === index ? (
                    <MdKeyboardArrowUp color='#620250' size={30} />
                  ) : (
                    <MdKeyboardArrowDown color='#620250' size={30} />
                  )}
                </span>
              </div>
              {activeIndex === index && (
                <p className='mt-2 lg:text-2xl font-sans font-normal text-[#620250]'>
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Faqs;
