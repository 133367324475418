/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <div className='bg-[#F8D6FF] px-4 pt-14 pb-8'>
        <h5 className='m-0 text-center lg:text-5xl font-sans font-semibold text-[#620250] '>
          CONTACT ME!
        </h5>
        <h3 className='text-center text-[#620250] font-normal font-sans text-md mt-6'>
          Do you need to contact me? please check <br /> these things:
        </h3>
        <div className='flex justify-center flex-col items-center'>
          <ul className='mt-14  lg:w-2/4'>
            <li className='lg:text-5xl font-sans font-semibold list-disc text-[#620250]'>
              HAVE YOU CHECKED THE <br />
              <span>
                <Link to='#' className=' text-[#DC5E00] hover:text-[#620250]'>
                  {" "}
                  FAQ
                </Link>
              </span>
              ?
            </li>
            <p className='mt-4  text-[#620250] font-sans font-normal lg:text-2xl'>
              Any question that is answered there I won't respond to, sorry!
            </p>
          </ul>

          <ul className='mt-14  lg:w-2/4'>
            <li className='lg:text-5xl font-sans font-semibold list-disc text-[#620250]'>
              IS IT A BUSINESS QUERY OR <br /> CASUAL?
            </li>
            <p className='mt-4 text-[#620250] font-sans font-normal lg:text-2xl'>
              If it's casual please message the Discord! That's where I engage
              with everyone in the community.{" "}
            </p>
            <p className='mt-4 text-[#620250] font-sans font-normal lg:text-2xl'>
              I'd love to respond to your emails as well but I'm super busy with
              the game! Talking with me through the server is the best way to
              talk to me :)
            </p>
          </ul>

          <ul className='mt-14  lg:w-2/4'>
            <li className='lg:text-5xl font-sans font-semibold list-disc text-[#620250]'>
              YOU’RE LOOKING TO JOIN THE TEAM/GET AN INTERNSHIP?
            </li>
            <p className='mt-4 text-[#620250] font-sans font-normal lg:text-2xl'>
              I’m so incredibly flattered! But sadly we’re quite far into
              production so I’m not looking for any additional people. It’s
              mainly me (Kela) working on the game and I don’t currently have
              enough time or the right environment to offer internships.{" "}
            </p>
            <p className='mt-4 text-[#620250] font-sans font-normal lg:text-2xl'>
              So sorry I can’t help you at this time but I wish you all the luck
              and if you ever see me around an event and want to chat please do
              reach out! :)
            </p>
          </ul>

          <p className='mt-4 text-[#620250] text-center font-sans font-semibold tracking-wider lg:text-2xl'>
            You're still reading? then let's email!​
          </p>
          <p className='mt-1 text-[#620250] text-center font-sans font-medium tracking-wider lg:text-2xl'>
            For Press inquiries please email:​
          </p>
          <div>
            <button className='relative cursor-pointer opacity-90 text-2xl font-semibold capitalize hover:opacity-100 transition-opacity p-[2px] font-sans bg-black rounded-[16px] bg-gradient-to-t from-[#8122b0] to-[#dc98fd] active:scale-95'>
              <span className='w-full h-full flex items-center gap-2 px-8 py-3 bg-[#B931FC] text-[#f1d5fe] rounded-[14px] bg-gradient-to-t from-[#a62ce2] to-[#c045fc]'>
                press@rablets.com
              </span>
            </button>
          </div>

          <p className='mt-4 text-[#620250] text-center font-sans font-normal tracking-wider lg:text-2xl'>
            You can reach me (SF):
          </p>
          <p className='mt-3 text-center text-[#620250] font-sans font-normal tracking-wider lg:text-xl'>
            (any press in this inbox will be <br /> forwarded to the above and{" "}
            <br /> therefore take longer, please email <br /> to the above)
          </p>
          <div>
            <button className='relative cursor-pointer opacity-90 text-2xl font-semibold capitalize hover:opacity-100 transition-opacity p-[2px] font-sans bg-black rounded-[16px] bg-gradient-to-t from-[#8122b0] to-[#dc98fd] active:scale-95'>
              <span className='w-full h-full flex items-center gap-2 px-8 py-3 bg-[#B931FC] text-[#f1d5fe] rounded-[14px] bg-gradient-to-t from-[#a62ce2] to-[#c045fc]'>
                hello@rablets.com
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
