/** @format */

import React from "react";

const Message = () => {
  return (
    <>
      <div className='min-h-screen bg-gradient-to-t from-[#FCECFF] via-[#D854F3] to-[#F0ADFF] px-4 py-20'>
        <div className='flex justify-center'>
          <div
            className='card  lg:w-3/6 px-6 py-4 '
            style={{ borderRadius: "30px", border: "none" }}>
            <p className='lg:text-3xl text-center font-sans font-normal lg:py-4 text-[#620250]'>
              With the button above you can see a full checklist of
              accessibility efforts in Rab Lets.
            </p>
            <p className='lg:text-3xl text-center font-sans font-normal lg:py-4 text-[#620250]'>
              At the bottom of this page you can find a breakdown of all the
              configurable settings in Rab Lets.
            </p>
            <p className='lg:text-3xl text-center font-sans font-medium  text-[#620250]'>
              Allow me to highlight a few features!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
