/** @format */

import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "Show letters HUD",
    answer: (
      <div>
        <p>
          This is by default on and allows you to view any deliveries that are
          ready to be delivered on your screen at all times. The letter icon
          also features a tiny portrait of the character it’s for so you can
          easily recognise them when you walk past.
        </p>
      </div>
    ),
  },
  {
    question: "show controls hub",
    answer: (
      <div>
        <p>
          This option is not turned on by default but can be enabled to have a
          convenient overview of the game’s controls on screen at all times. The
          game will adapt the displayed controls to the input device you’re
          using (keyboard, xbox controller etc.).
        </p>
      </div>
    ),
  },
  {
    question: "input sensitivity",
    answer: (
      <div>
        <p>
          Using this slider you can make the game more or less sensitive to
          input, specifically this can really help if you find the camera too
          slow to react or too fast.
        </p>
        <p>
          Currently, this does not affect regular button input. An additional
          setting is planned for the future to allow a button cooldown so things
          cannot be triggered again accidentally.
        </p>
      </div>
    ),
  },
  {
    question: "switch joysticks",
    answer: (
      <div>
        <p>
          If you would prefer the joysticks on your controller to respond the
          opposite way (switch camera and movement) you can toggle this!
        </p>
      </div>
    ),
  },
  {
    question: "invert camera x",
    answer: (
      <div>
        <p>
          Some players, especially those that grew up with older generation
          games, will prefer the horizontal movement of the camera to respond
          the opposite way. Toggling this can help you customize the controls to
          your liking.
        </p>
      </div>
    ),
  },
  {
    question: "invert camera y",
    answer: (
      <div>
        <p>
          Some players, especially those that grew up with older generation
          games, will prefer the vertical movement of the camera to respond the
          opposite way. Toggling this can help you customize the controls to
          your liking.
        </p>
      </div>
    ),
  },
  {
    question: "controller",
    answer: (
      <div>
        <p>
          The game will attempt to detect what type of controller you are using
          but in case it gets it wrong you can use this dropdown to easily
          switch. Changing this will affect all button prompts shown in the game
          to correspond with your chosen controller.
        </p>
      </div>
    ),
  },
];
const Faq5 = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to toggle the active FAQ item
  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className='bg-[#F8D6FF] px-4 py-12'>
        <div className='container'>
          <div className='row gap-14'>
            <div className='col-md-4'>
              <h2 className='lg:text-4xl font-sans font-bold py-2 text-[#620250]'>
                ACCESSIBILITY SETTINGS
              </h2>
              <p className='lg:text-xl font-sans font-normal  text-[#620250]'>
                On the bottom right side of the menu you can find the
                “accessibility” sub-menu. (This menu will soon be renamed to
                “Controls” as it’s more apt for the contents.)
              </p>
              <img
                src='https://i.imgur.com/3OW87HI.png'
                className='img-fluid rounded-3xl'
                alt=''
              />
            </div>
            <div className='col-md-7'>
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className='mb-4 pt-4 border-t-[1px] border-[#620250]'>
                  <div
                    className='flex justify-between items-center cursor-pointer'
                    onClick={() => toggleFAQ(index)}>
                    <h2 className='lg:text-center lg:text-2xl text-lg font-sans uppercase font-semibold text-[#620250] '>
                      {faq.question}
                    </h2>
                    <span className='text-blue-500'>
                      {activeIndex === index ? (
                        <MdKeyboardArrowUp color='#620250' size={30} />
                      ) : (
                        <MdKeyboardArrowDown color='#620250' size={30} />
                      )}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <p className='mt-2 lg:text-xl font-sans font-normal text-[#620250]'>
                      {faq.answer}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq5;
