import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "audio sliders",
    answer: (
      <div>
        <p>
          You can adjust the game’s music, sound effects and ambient sound
          volume separately with sliders.
        </p>
        <p>Moving the sliders all the way to the left will mute the game.</p>
      </div>
    ),
  },
  {
    question: "Language",
    answer: (
      <div>
        <p>The language can be switched at any time. Available languages:</p>
        <ul>
          <li className="list-disc">English (original language)</li>
          <li className="list-disc">European Spanish</li>
          <li className="list-disc">European French</li>
          <li className="list-disc">German</li>
          <li className="list-disc">Brazillian Portugese</li>
          <li className="list-disc">Japanese</li>
          <li className="list-disc">Simplified Chinese</li>
          <li className="list-disc">Korean</li>
        </ul>
        <p>
          When you change your language to French you might have to re-select
          your pronouns.
        </p>
        <p>
          This is because the French language currently does not support gender
          neutral language so if you had selected they/them pronouns you will
          have to switch to something else.
        </p>
      </div>
    ),
  },
  {
    question: "player",
    answer: (
      <div>
        <p>
          You can at any time access this page to change anything about your
          player character that you customized at the start of your game.
        </p>
        <p>
          There’s currently not a preview of your player character available
          within that menu but that’s something that will be added in the next
          update.
        </p>
      </div>
    ),
  },
  {
    question: "pronoun",
    answer: (
      <div>
        <p>
          At any time can you switch pronouns between they/them, she/her and
          he/him. For the French language gender neutral pronouns are not
          available.
        </p>
      </div>
    ),
  },
  {
    question: "reset button",
    answer: (
      <div>
        <p>
          A big red button to reset all settings to default. This will exclude
          player customization, pronouns and language as those don’t have a
          ‘default’.
        </p>
      </div>
    ),
  },
  {
    question: "warning button",
    answer: (
      <div>
        <p>
          Hopefully you never have to use this but with the yellow triangle
          button you can access a menu that includes a ‘reset location’ button
          in case you are stuck.
        </p>
        <p>
          Additionally, this menu displays your system information (GPU, CPU,
          RAM and FPS) in case you need to fill out a bug report where that info
          is relevant.
        </p>
        <h2 className="lg:text-2xl text-lg">
          For bug reports please join the
          <Link
            to="#"
            className="no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]"
          >
            Discord
          </Link>
          or email me:
          <Link
            to="#"
            className="no-underline font-semibold text-[#DC5E00] hover:text-[#DC5E00]"
          >
            admin@rablets.com
          </Link>
          .
        </h2>
      </div>
    ),
  },
];

const Faq1 = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    // Function to toggle the active FAQ item
    const toggleFAQ = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  return (
    <>
    <div className="bg-[#F8D6FF] px-4 py-12">
        <div className="container">
        <div className="row gap-14">
            <div className="col-md-4">
              <h2 className="lg:text-4xl font-sans font-bold py-2 text-[#620250]">
                QUICK ACCESS
              </h2>
              <p className="lg:text-xl font-sans font-normal  text-[#620250]">
                These are settings that are very quickly available on the left
                side of the settings page.
              </p>
              <img
                src="https://i.imgur.com/3OW87HI.png"
                className="img-fluid rounded-3xl"
                alt=""
              />
            </div>
            <div className="col-md-7">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className="mb-4 pt-4 border-t-[1px] border-[#620250]"
                >
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h2 className="text-center lg:text-2xl text-lg  font-sans uppercase font-semibold text-[#620250] ">
                      {faq.question}
                    </h2>
                    <span className="text-blue-500">
                      {activeIndex === index ? (
                        <MdKeyboardArrowUp color="#620250" size={30} />
                      ) : (
                        <MdKeyboardArrowDown color="#620250" size={30} />
                      )}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <p className="mt-2 lg:text-xl font-sans font-normal text-[#620250]">
                      {faq.answer}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        </div>
         
    </>
  )
}

export default Faq1