/** @format */

import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "Automatic Camera Reset",
    answer: (
      <div>
        <p>
          the camera in Rab Lets can always be reset to its default position by
          clicking the mouse (or a joystick on controller) but with automatic
          camera reset ON the camera will reset after 3 seconds of no input.
        </p>
        <p>
          This can be a good way to reframe the camera regularly if you have
          difficulty doing so yourself.
        </p>
      </div>
    ),
  },
  {
    question: "camera assist",
    answer: (
      <div>
        <p>
          With camera assist the camera dynamically turns with the player on
          movement input. It is less harsh than locking the camera behind the
          player but does allow you to play completely without the need to
          control the camera.
        </p>
        <p>
          Camera assist is enabled by default but can be toggled off in the
          settings for complete control over the camera.
        </p>
        <p className='font-thin'>
          Turning this off helps some people with vertigo/motion sickness.
        </p>
      </div>
    ),
  },
  {
    question: "Lock Follow Camera",
    answer: (
      <div>
        <p>
          The camera can be locked to behind the player. In comparison to camera
          assist this is a bit more harsh but can be a great option if you have
          real difficulty controlling the camera and want a more static solution
          than camera assist.
        </p>
      </div>
    ),
  },
  {
    question: "Camera Max Distance",
    answer: (
      <div>
        <p>
          When moving the camera will by default pull back a bit to show more of
          the environment around the player. The intent is to allow better
          platforming and more visibility for finding collectibles.
        </p>
        <p>
          However, this might not be convenient for you so you can adjust how
          much the camera pulls back with the slider.
        </p>
        <p>
          Moving the slider all the way to the left (when the number shows 0)
          will disable this feature entirely.
        </p>
        <p className='font-thin'>
          Turning this off helps some people with vertigo/motion sickness.
        </p>
      </div>
    ),
  },
  {
    question: "rotating lag",
    answer: (
      <div>
        <p>
          The game automatically applies some smoothing to whenever the camera
          turns. You can increase or decrease this effect with the slider.
        </p>
        <p>
          Moving the slider all the way to the left (when the number shows 0)
          will disable this feature entirely.
        </p>
        <p className='font-thin'>
          Turning this off helps some people with vertigo/motion sickness.
        </p>
      </div>
    ),
  },
  {
    question: "fov",
    answer: (
      <div>
        <p>
          Field of View (FOV) is essentially how much you can see without
          turning the camera.
        </p>
        <p>
          What a natural FOV is depends on how far you are from your display and
          so I would encourage you to play around with it. By default the FOV is
          set to 90 which would be good for when you are close to the display
          like a PC setup.
        </p>
      </div>
    ),
  },
];
const Faq4 = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to toggle the active FAQ item
  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className='bg-[#e7b4f1] px-4 py-12'>
        <div className='container'>
          <div className='row gap-14'>
            <div className='col-md-4'>
              <h2 className='lg:text-4xl font-sans font-bold py-2 text-[#620250]'>
                CAMERA SETTINGS
              </h2>
              <p className='lg:text-xl font-sans font-normal  text-[#620250]'>
                On the right side of the settings page there’s a sub menu
                selector, this concerns the third section ‘Camera’.
              </p>
              <img
                src='https://i.imgur.com/HXMXAWM.png'
                className='img-fluid rounded-3xl'
                alt=''
              />
            </div>
            <div className='col-md-7'>
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className='mb-4 pt-4 border-t-[1px] border-[#620250]'>
                  <div
                    className='flex justify-between items-center cursor-pointer'
                    onClick={() => toggleFAQ(index)}>
                    <h2 className='text-center lg:text-2xl text-lg font-sans uppercase font-semibold text-[#620250] '>
                      {faq.question}
                    </h2>
                    <span className='text-blue-500'>
                      {activeIndex === index ? (
                        <MdKeyboardArrowUp color='#620250' size={30} />
                      ) : (
                        <MdKeyboardArrowDown color='#620250' size={30} />
                      )}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <p className='mt-2 lg:text-xl font-sans font-normal text-[#620250]'>
                      {faq.answer}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq4;
