/** @format */

import React from "react";

const Grid = () => {
  return (
    <>
      <div className=' bg-[#F8D6FF] px-4 pt-20'>
        <div className='container grid lg:grid-cols-2 gap-4'>
          <div>
            <img
              src='https://i.imgur.com/qUlxROd.png'
              className=' rounded-lg'
              alt=''
            />
            <h2 className='lg:text-3xl text-center font-sans font-semibold py-4 text-[#620250]'>
              CAMERA ASSIST
            </h2>
            <p className='lg:text-xl font-sans font-normal  text-[#620250]'>
              The camera assist feature dynamically adjusts the camera to follow
              the player's movements, providing a smoother experience compared
              to locking the camera directly behind the player. This feature
              enables players to engage in the game without the necessity of
              manually controlling the camera.
            </p>
          </div>
          <div>
            <img
              src='https://i.imgur.com/Gzsm6Q3.png'
              className=' rounded-lg'
              alt=''
            />
            <h2 className='lg:text-3xl text-center font-sans font-semibold py-4 text-[#620250]'>
              AUTOMATIC CAMERA RESET
            </h2>
            <p className='lg:text-xl font-sans font-normal  text-[#620250]'>
              In Rab Lets, the camera can always be reset to its default
              position by clicking the mouse. However, with the automatic camera
              reset function, the camera will reset after 3 seconds of no input.
            </p>
            <p className='lg:text-xl font-sans font-normal  text-[#620250]'>
              This can be a good way to reframe the camera regularly if you have
              difficulty doing so yourself.
            </p>
          </div>
        </div>
        <div className='container grid lg:grid-cols-2 gap-4'>
          <div>
            <img
              src='https://i.imgur.com/Fm9RBq9.png'
              className='img-fluid rounded-lg'
              alt=''
            />
            <h2 className='lg:text-3xl text-center font-sans font-semibold py-4 text-[#620250]'>
              COLOR CODED CHARACTERS
            </h2>
            <p className='lg:text-xl font-sans font-normal lg:py-4 text-[#620250]'>
              Managing the numerous characters to meet and deliveries can be
              challenging, making it difficult to keep track of each
              individual's identity.
            </p>
            <p className='lg:text-xl font-sans font-normal lg:py-4 text-[#620250]'>
              Rab Lets includes a few features to help you:
            </p>
            <ul>
              <li className='list-disc lg:text-xl font-sans font-normal  text-[#620250]'>
                Every character is assigned a unique color that is consistently
                used whenever their name appears in dialogue or the user
                interface (UI).
              </li>
              <li className='list-disc lg:text-xl font-sans font-normal  text-[#620250]'>
                An icon depicting a character's face will be displayed next to
                their name the first time it's mentioned in a conversation or
                anytime it appears in the user interface (UI).
              </li>
              <li className='list-disc lg:text-xl font-sans font-normal  text-[#620250]'>
                Active deliveries can be seen at any time in the handbook (H to
                open)
              </li>
              <li className='list-disc lg:text-xl font-sans font-normal text-[#620250]'>
                More and more hints on what to do will be given if you return to
                talk to the character that gave you a delivery.
              </li>
            </ul>
          </div>
          <div>
            <img
              src='https://i.imgur.com/5OnAF4S.png'
              className=' rounded-lg'
              alt=''
            />
            <h2 className='lg:text-3xl text-center font-sans font-semibold py-4 text-[#620250]'>
              AUTOMATIC CAMERA RESET
            </h2>
            <p className='lg:text-xl font-sans font-normal  text-[#620250]'>
              Within Rab Lets' main menu, you have the option to choose 'Quick
              Start' instead of 'New Game', allowing you to bypass player
              creation entirely and dive straight into the game.
            </p>
            <p className='lg:text-xl font-sans font-normal  text-[#620250]'>
              Upon selecting 'Quick Start', a random player character will be
              generated for you, complete with a name and corresponding
              pronouns. You can modify these details at any time through the
              game's Handbook menu, accessible by pressing 'H' or 'Escape'.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Grid;
