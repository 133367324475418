import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Base from './components/Layouts/Base';
import Faqs from './components/Utils/Faqs';
import Contact from './pages/Contact';
import Presskit from './pages/Presskit';
import Accessibility from './pages/Accessibility';


function App() {
  return (
    <div className="App">
       <BrowserRouter>
      <Base>
        <Routes>
          <Route index path="/" element={<Home/>} />
          <Route  path="/faqs" element={<Faqs/>} />
          <Route  path="/contact" element={<Contact/>} />
          {/* <Route  path="/presskit" element={<Presskit/>} /> */}
          <Route  path="/accessibility" element={<Accessibility/>} />
          {/* <Route path="/*" element={<PageNotFound />}/> */}
        </Routes>
      </Base>
    </BrowserRouter>
    </div>
  );
}

export default App;
